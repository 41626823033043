import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Backbreeze CSS a utility class library written in Sass" isHome={true} subline="Backbreeze is a utility class library written in Sass. It provides classes that help you create custom designs fast.">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`Written in `}<a parentName="li" {...{
          "href": "https://sass-lang.com/"
        }}>{`Sass`}</a>{`, configuration in Sass.`}</li>
      <li parentName="ul">{`Designed to build project specific style systems, project specific configuration instead of lots of defaults.`}</li>
      <li parentName="ul">{`Can be dropped in to existing Sass projects.`}</li>
      <li parentName="ul">{`Responsive and mobile first.`}</li>
      <li parentName="ul">{`Short class names derived from the CSS property they apply to make `}<em parentName="li">{`guessing`}</em>{` class names easy.`}</li>
    </ul>
    <h2 {...{
      "id": "what-are-utility-classes"
    }}>{`What are utility classes?`}</h2>
    <p>{`Utility classes are CSS classes that only apply one CSS property and value. The class name reflects what property and value are applied.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.ta-center {
  text-align: center;
}
`}</code></pre>
    <p>{`Styling is achieved by combining multiple classes in HTML rather then writing, and possibly repeating, CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="ta-center fs-30 lh-1.2 fw-bold">
  <!--  -->
</div>
`}</code></pre>
    <p>{`Backbreeze provides classes for a large number of CSS properties. This allows to resolve most styling situations by just combining classes, eliminating the need to write new CSS. Some other benefits of this approach are:`}</p>
    <ul>
      <li parentName="ul">{`Fast development`}</li>
      <li parentName="ul">{`Small CSS files (when `}<a parentName="li" {...{
          "href": "/using-in-production/#remove-unused-css-with-purgecss"
        }}>{`processed correctly`}</a>{`)`}</li>
      <li parentName="ul">{`No need to worry about naming things`}</li>
      <li parentName="ul">{`Flexibility to make exceptions / variations of components any time`}</li>
    </ul>
    <h2 {...{
      "id": "why-sass"
    }}>{`Why Sass?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://sass-lang.com/"
      }}>{`Sass`}</a>{` is a widely used industry standard for authoring stylesheets. In contrast to combining different PostCSS plugins it has a `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation"
      }}>{`full language spec`}</a>{` that provides a solid authoring experience and makes it easy to port code between projects. PostCSS is great to process the generated CSS and its use is encouraged when using `}<a parentName="p" {...{
        "href": "/using-in-production/"
      }}>{`Backbreeze in production`}</a>{`.`}</p>
    <h2 {...{
      "id": "inspired-by-tailwind"
    }}>{`Inspired by Tailwind`}</h2>
    <p>{`Backbreeze is inspired by the `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/"
      }}>{`Tailwind CSS`}</a>{` framework. It aims to be an alternative that you can drop into existing projects that use Sass.`}</p>
    <h2 {...{
      "id": "who-is-this-library-for"
    }}>{`Who is this library for?`}</h2>
    <ul>
      <li parentName="ul">{`Developers that enjoy working with utility classes and appreciate the approach.`}</li>
      <li parentName="ul">{`Developers that like to work with Sass.`}</li>
      <li parentName="ul">{`Developers that want to migrate existing Sass projects to use utility classes.`}</li>
    </ul>
    <h2 {...{
      "id": "backbreeze-in-production"
    }}>{`Backbreeze in production`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gbm-telecom.ch/"
        }}>{`https://www.gbm-telecom.ch/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bold.ch/"
        }}>{`https://www.bold.ch/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      